import React, { ReactElement, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import AdminManagePoints from './components/AdminManagePoints';
import AdminManageNotices from './components/AdminManageNotices';
import AdminManageUsers from './components/AdminManageUsers';
import AdminManageWithdraws from './components/AdminManageWithdraws';
import Main from './components/Main';
import Signin from './components/Signin';

import AuthContext from './token-context';
import AdminManageEnquiries from './components/AdminManageEnquiries';
import AdminManageFAQs from './components/AdminManageFAQs';
import AdminWriteNotice from './components/AdminWriteNotice';
import AdminAnswerEnquiry from './components/AdminAnswerEnquiry';
import AdminWriteFAQ from './components/AdminWriteFAQ';
import AdminManageBusinessUsers from './components/AdminManageBusinessUsers';
import AdminManageBusinessPoints from './components/AdminManageBusinessPoints';
import AdminManageBusinessWithdraws from './components/AdminManageBusinessWithdraws';
import AdminManageBusinessIncentives from './components/AdminManageBusinessIncentives';
import AdminManageBusinessEnquiries from './components/AdminManageBusinessEnquiries';
import BusinessTree from './components/BusinessTree';

export default function App(): ReactElement {
  const [token, setToken] = useState(localStorage.getItem('session'));

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      <Router>
        <Switch>
          <Route render={(props) => <Main {...props} />} path="/" exact={true} />
          <Route render={(props) => <Signin {...props} />} path="/signin" />
          <Route render={(props) => <AdminManageUsers {...props} />} path="/manage-users" />
          <Route render={(props) => <AdminManageBusinessUsers {...props} />} path="/manage-business-users" />
          <Route render={(props) => <BusinessTree {...props} />} path="/business-group" />
          <Route render={(props) => <AdminManagePoints {...props} />} path="/manage-points" />
          <Route render={(props) => <AdminManageBusinessPoints {...props} />} path="/manage-business-points" />
          <Route render={(props) => <AdminManageWithdraws {...props} />} path="/manage-withdraws" />
          <Route render={(props) => <AdminManageBusinessWithdraws {...props} />} path="/manage-business-withdraws" />
          <Route render={(props) => <AdminManageNotices {...props} />} path="/manage-notices" />
          <Route render={(props) => <AdminManageEnquiries {...props} />} path="/manage-enquiries" />
          <Route render={(props) => <AdminManageBusinessEnquiries {...props} />} path="/manage-business-enquiries" />
          <Route render={(props) => <AdminManageFAQs {...props} />} path="/manage-faqs" />
          <Route render={(props) => <AdminWriteNotice {...props} />} path="/write-notice" />
          <Route render={(props) => <AdminAnswerEnquiry {...props} />} path="/answer-enquiry" />
          <Route render={(props) => <AdminWriteFAQ {...props} />} path="/write-faq" />
          <Route render={(props) => <AdminManageBusinessIncentives {...props} />} path="/manage-incentives" />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
