import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { IncentiveInfo, listIncentives, revokeIncentive } from '../api';
import 'dayjs';

import Header from './Header';
import Footer from './Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import '../css/AdminManageUsers.css';
import '../css/AdminManageBusinessUsers.css';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 110px 150px 180px 150px 110px 160px 80px;
  align-items: center;
  h1 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
  }
  h2 {
    font-family: NotoSansKR;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e0be7a;
    text-align: left;
  }
  h3 {
    font-family: NotoSansKR;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  h4 {
    font-family: NotoSansKR;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  .points {
    display: flex;
    h1 {
      font-family: NotoSansKR;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #e0be7a;
      text-align: left;
    }
    h2 {
      font-family: NotoSansKR;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #acacac;
      text-align: left;
    }
  }
  .tooltipBtn {
    font-family: NotoSansKR;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e0be7a;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
  }
  .cancelBtn {
    width: 80px;
    height: 30px;
    padding: 7px;
    border-radius: 4px;
    border: solid 1px #e0be7a;
    background: none;
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e0be7a;
    :disabled {
      background-color: #e0be7a;
      color: #1c1b1a;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

import Icon_KR from 'img/korea.png';
import Icon_Vietnam from 'img/vietnam.png';
import dayjs from 'dayjs';

const CountryInfo = [
  { number: '+82', icon: Icon_KR },
  { number: '+84', icon: Icon_Vietnam },
];

export default withRouter(function AdminManageBusinessIncentives(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const maxUserCount = 10;
  const maxPageCount = 9;

  const [page, setPage] = useState<number>(0);

  const [allIncentives, setAllIncentives] = useState<IncentiveInfo[]>([]);
  const [incentives, setIncentives] = useState<IncentiveInfo[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const session = localStorage.getItem('session');

    if (!session) {
      props.history.push('/signin');
      return;
    }

    (async () => {
      const result = await listIncentives();
      if (result == null) {
        return;
      }
      setAllIncentives(result);
      setIncentives(result);
    })();
  }, []);

  useEffect(() => {
    // setIncentives(
    //   allIncentives.filter(
    //     (info) =>
    //       info.email.toLowerCase().includes(search.toLowerCase()) ||
    //       info.username.toLowerCase().includes(search.toLowerCase()) ||
    //       info.phone.replace('-', '').replace('+', '').includes(search.replace('-', '').replace('+', '')),
    //   ),
    // );
  }, [search]);

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        <div className="admin-incentives-box">
          <div style={{ display: 'flex', height: '35px' }}>
            <div className="title">수당지급 관리</div>
            <span style={{ margin: 'auto' }} />
            <input className="admin-box-search" placeholder="검색" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div style={{ height: '20px' }} />
          <Columns>
            <h1>유저 아이디</h1>
            <h1>날짜/시간</h1>
            <h1>이메일</h1>
            <h1>회원권</h1>
            <h1>보유 포인트</h1>
            <h1>지급 정보</h1>
            <h1></h1>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {incentives.slice(page * 10, Math.min((page + 1) * 10, incentives.length)).map((i) => (
            <RowData info={i} />
          ))}
          {incentives.length > maxUserCount && (
            <>
              <div style={{ height: '10px' }} />
              <div style={{ display: 'flex', margin: '0 auto' }}>
                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.max(0, val - 1));
                  }}
                >
                  <img src={PrevIcon} />
                </button>
                {Math.ceil(incentives.length / maxUserCount) > maxPageCount ? (
                  <>
                    <button className="admin-page" onClick={() => setPage(0)} disabled={page == 0}>
                      1
                    </button>
                    {page > maxPageCount / 2 && <div className="admin-page">…</div>}
                    {Array.from({ length: maxPageCount - 2 }, (v, i) => {
                      const pageNum =
                        Math.min(
                          Math.max(Math.floor(maxPageCount / 2), page),
                          Math.ceil(incentives.length / maxUserCount) - Math.ceil(maxPageCount / 2),
                        ) +
                        i -
                        Math.floor((maxPageCount - 2) / 2);
                      return (
                        <button className="admin-page" onClick={() => setPage(pageNum)} disabled={page == pageNum}>
                          {pageNum + 1}
                        </button>
                      );
                    })}
                    {page < Math.ceil(incentives.length / maxUserCount) - Math.ceil(maxPageCount / 2) && (
                      <div className="admin-page">…</div>
                    )}
                    <button
                      className="admin-page"
                      onClick={() => setPage(Math.ceil(incentives.length / maxUserCount) - 1)}
                      disabled={page == Math.ceil(incentives.length / maxUserCount) - 1}
                    >
                      {Math.ceil(incentives.length / maxUserCount)}
                    </button>
                  </>
                ) : (
                  <>
                    {Array.from({ length: Math.ceil(incentives.length / maxUserCount) }, (v, i) => {
                      return (
                        <button className="admin-page" onClick={() => setPage(i)} disabled={page == i}>
                          {i + 1}
                        </button>
                      );
                    })}
                  </>
                )}

                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.min(val + 1, Math.ceil(incentives.length / maxUserCount) - 1));
                  }}
                >
                  <img src={NextIcon} />
                </button>
              </div>
            </>
          )}
          <div style={{ height: '20px' }} />
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { info: IncentiveInfo }) => {
  return (
    <div key={props.info.id}>
      <ReactTooltip
        id={`points_${props.info.id}`}
        getContent={() => {
          return (
            <div className="admin-business-tooltip">
              <div className="point">
                <h1>데모 포인트</h1>
                <h2>{props.info.user.pointA}p</h2>
              </div>
              <h1>데모 포인트(미승인): {props.info.user.pendingPointA}p</h1>
              <div style={{ height: '7px' }} />
              <div className="point">
                <h1>토너먼트 포인트</h1>
                <h2>{props.info.user.pointB}p</h2>
              </div>
              <h1>토너먼트 포인트(미승인): {props.info.user.pendingPointB}p</h1>
              <div style={{ height: '7px' }} />
              <div className="point">
                <h1>라이브 포인트</h1>
                <h2>{props.info.user.pointC}p</h2>
              </div>
              <h1>라이브 포인트(미승인): {props.info.user.pendingPointC}p</h1>
              <div style={{ height: '7px' }} />
              <div className="point">
                <h1>이더리움 포인트</h1>
                <h2>{props.info.user.pointD}p</h2>
              </div>
              <h1>이더리움 포인트(미승인): {props.info.user.pendingPointD}p</h1>
            </div>
          );
        }}
        place="bottom"
        offset={{ right: 95 }}
        clickable={false}
        type={'dark'}
        arrowColor={'transparent'}
        key={`${props.info.id}_tooltip`}
      />
      <Row>
        <Columns>
          <h2>{props.info.user.username}</h2>
          <h3>{dayjs(props.info.createdAt).format('YYYY-MM-DD HH:mm:ss')}</h3>
          <h3>{props.info.user.email}</h3>
          <h3>{props.info.user.membership.name}</h3>
          <button className="tooltipBtn" data-for={`points_${props.info.id}`} data-tip="" key={`${props.info.id}_showtooltip`}>
            포인트 보기
          </button>
          <div className="points">
            <h1>
              {props.info.point == 'pointA'
                ? '데모포인트'
                : props.info.point == 'pointB'
                ? '토너먼트포인트'
                : props.info.point == 'pointC'
                ? '라이브포인트'
                : props.info.point == 'pointD'
                ? '이더리움포인트'
                : '알수없는포인트'}
            </h1>
            <div style={{ width: '5px' }} />
            <h2>{props.info.amount}p지급</h2>
          </div>
          <button
            className="cancelBtn"
            disabled={props.info.state == 'revoked'}
            onClick={() => {
              (async () => {
                const result = await revokeIncentive(props.info.id);
                if (result && result) {
                  window.location.reload();
                }
              })();
            }}
          >
            {props.info.state == 'revoked' ? '취소 완료' : '수당 지급 취소'}
          </button>
        </Columns>
      </Row>
    </div>
  );
};
