//@ts-nocheck

import React, { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Modal from 'react-modal';
import { getBusinessTree, StructedUserTree } from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/BusinessTree.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

import dayjs from 'dayjs';
// import styled from 'styled-components';

// import { Motion, spring } from 'react-motion';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

export default withRouter(function BusinessTree(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [treeMembers, setTreeMembers] = useState<StructedUserTree[]>([]);
  const [username, setUserName] = useState('');
  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const username = urlParams.get('username');
      if (username) {
        setUserName(username);
        const members = await getBusinessTree(username);
        if (!members) {
          console.log('tree 가 없음');
        } else {
          console.log(members);
          console.log(members.length);
          setTreeMembers(members);
        }
      }
    })();
  }, []);

  function copyText(text: string) {
    if (!document.queryCommandSupported('copy')) {
      return alert(t('cannot-copy'));
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.dark(t('copy-success'), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    });
  }

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', width: '940px', margin: '80px auto 180px' }}>
        <div style={{ display: 'flex' }}>
          <Link className="BusinessTree-btn" to="/manage-business-users">
            이전 화면으로 돌아가기
          </Link>
        </div>
        <div style={{ height: '20px' }} />
        <div className="BusinessTree-box">
          <h1>{username}의 그룹</h1>
          <h2>*하부 7대 까지만 보이게 됩니다.</h2>
          <div style={{ height: '12px' }} />
          <div className="BusinessTree-box-mask">
            <Table isTree defaultExpandAllRows={true} bordered cellBordered rowKey="username" height={445} data={treeMembers}>
              <Column width={250} align="left" fixed resizable>
                <HeaderCell>아이디</HeaderCell>
                <Cell dataKey="username" />
              </Column>
              <Column fixed resizable>
                <HeaderCell>회원권</HeaderCell>
                <Cell dataKey="membership" />
              </Column>
              <Column width={200} fixed resizable>
                <HeaderCell>회원상태(승인일)</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.wasMembershipApproved == true ? '정회원' : '준회원'} ${
                      d.wasMembershipApprovedAt == null ? '' : dayjs(d.wasMembershipApprovedAt).format('(YY-MM-DD)')
                    }`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>직접추천수당 L.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.level1PointC ? d.level1PointC : 0}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>직접추천수당 E.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.level1PointD ? d.level1PointD : 0}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>팀장인센티브 L.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.level2PointC ? d.level2PointC : 0}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>팀장인센티브 E.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.level2PointD ? d.level2PointD : 0}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>그룹(7Lv)총 매출액</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.totalLevel7Sales}$`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>그룹장조건 달성 여부</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return d.canReceiveLevel7Incentives == true ? '완료' : '미완료';
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>그룹장 인센티브 정산 예정 L.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.level7PointC ? d.level7PointC : 0}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>보유 D.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.pointA}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>보유 T.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.pointB}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>보유 L.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.pointC}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>보유 E.P</HeaderCell>
                <Cell>
                  {(d, i) => {
                    return `${d.pointD}p`;
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Lv1</HeaderCell>
                <Cell>
                  {(d, i) => {
                    if (d.sales) return `${d.sales[0].count}명 / ${d.sales[0].sales}$`;
                    else return '';
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Lv2</HeaderCell>
                <Cell>
                  {(d, i) => {
                    if (d.sales) return `${d.sales[1].count}명 / ${d.sales[1].sales}$`;
                    else return '';
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Lv3</HeaderCell>
                <Cell>
                  {(d, i) => {
                    if (d.sales) return `${d.sales[2].count}명 / ${d.sales[2].sales}$`;
                    else return '';
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Lv4</HeaderCell>
                <Cell>
                  {(d, i) => {
                    if (d.sales) return `${d.sales[3].count}명 / ${d.sales[3].sales}$`;
                    else return '';
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Lv5</HeaderCell>
                <Cell>
                  {(d, i) => {
                    if (d.sales) return `${d.sales[4].count}명 / ${d.sales[4].sales}$`;
                    else return '';
                  }}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Lv6</HeaderCell>
                <Cell>
                  {(d, i) => {
                    if (d.sales) return `${d.sales[5].count}명 / ${d.sales[5].sales}$`;
                    else return '';
                  }}
                </Cell>
              </Column>
            </Table>
          </div>
        </div>
      </div>

      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});
